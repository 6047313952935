
.ant-select-selection-overflow {
    flex-wrap: nowrap;
}


.ant-table-tbody > tr {
    cursor: pointer;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    text-shadow: 1px 0 0 black;
    background-color: #F5EEF8;
}

.ant-select.ant-select-multiple.ant-select-allow-clear.ant-select-show-search {
    overflow: hidden;
}
